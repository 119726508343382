import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { HeaderContext } from 'app/containers/Header'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo, useContext } from 'react'

import { Benefits } from './Benefits'
import { Syncrobox } from './Syncrobox'

export interface Props {
  onClick?: () => void
  modalStatus?: boolean
  syncroStatus?: SyncroStatus
}

export const ModalIBE = memo(function ModalIBE({
  onClick,
  modalStatus,
  syncroStatus,
}: Props) {
  const { IBEID, languageCode, languagePrefix } = useContext(
    HeaderContext,
  ) as any

  return (
    <Container onClick={onClick} row stretch>
      <LeftSide dial={3}>
        <Benefits modalStatus={modalStatus} />
      </LeftSide>
      <RightSide onClick={(e) => e.stopPropagation()}>
        <Title>{useVocabularyData('book-now', languageCode)}</Title>
        <Subtitle>
          {useVocabularyData('ibe-modal-title', languageCode)}
        </Subtitle>

        {IBEID ? (
          <Syncrobox
            IBEID={IBEID}
            languagePrefix={languagePrefix || 'IT'}
            modalStatus={modalStatus}
            syncroStatus={syncroStatus}
          />
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  height: 100%;
`

const LeftSide = styled(FlexBox)`
  width: 55%;
  height: 100%;
  background: ${rgba(theme.colors.variants.neutralDark4, 0.2)};
`

const RightSide = styled.div`
  width: 45%;
  background: ${({ theme }) => theme.colors.variants.primaryLight2};
  padding: 19.3vh 6.806vw 0;
  position: relative;
  z-index: 2;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.9375rem;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-size: 300;
  line-height: 2rem;
`

export type SyncroStatus = 'default' | 'open'
