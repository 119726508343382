import * as React from 'react'
import { memo } from 'react'

const SvgChevronLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 23.688 23.688"
  >
    <path d="M12.374.53 1.06 11.844l11.314 11.313" />
  </svg>
)

const Memo = memo(SvgChevronLeft)
export default Memo
