import * as React from 'react'
import { memo } from 'react'
const SvgEmailFull = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20.776 16"
  >
    <path d="M1.791 16a1.74 1.74 0 0 1-1.284-.507A1.74 1.74 0 0 1 0 14.209V1.791A1.74 1.74 0 0 1 .507.507 1.74 1.74 0 0 1 1.791 0h17.194a1.74 1.74 0 0 1 1.284.507 1.74 1.74 0 0 1 .507 1.284v12.418a1.74 1.74 0 0 1-.507 1.284 1.74 1.74 0 0 1-1.284.507Zm8.6-8.179 9.552-6.328-.478-.657-9.075 5.97L1.315.836l-.478.657Z" />
  </svg>
)
const Memo = memo(SvgEmailFull)
export default Memo
