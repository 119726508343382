import * as React from 'react'
import { memo } from 'react'

const SvgTriangleRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 8 10.667"
  >
    <path d="m8 5.333-8 5.333V0Z" />
  </svg>
)

const Memo = memo(SvgTriangleRight)
export default Memo
