import * as React from 'react'
import { memo } from 'react'

const SvgChevronRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 23.688 23.688"
  >
    <path d="m11.313.53 11.314 11.314-11.314 11.314" />
  </svg>
)

const Memo = memo(SvgChevronRight)
export default Memo
