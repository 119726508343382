import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme'
import React, { memo } from 'react'

export interface Props {
  onClick: () => void
  variant?: Variant
}

export const ModalClose = memo(function ModalClose({
  onClick,
  variant = 'L',
}: Props) {
  return <Container onClick={onClick} variant={variant} />
})

const Container = styled.div<ContainerProps>`
  width: 8.4375rem;
  height: 8.4375rem;
  background: ${({ theme }) => theme.colors.variants.secondaryDark1};
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.6s ease-in-out;
  z-index: 3;
  &:before,
  &:after {
    content: '';
    width: 3rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    top: 3.9375rem;
    left: 2.6875rem;
    transition: 0.2s ease-out;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:hover {
    &:before,
    &:after {
      transform: none;
    }
  }

  ${({ variant }) => {
    switch (variant) {
      case 'R':
        return css`
          background: ${theme.colors.variants.primaryLight3};
          right: 0;
          left: auto;
        `
      case 'compact':
        return css`
          width: 3.75rem;
          height: 3.75rem;
          background: ${theme.colors.variants.primaryLight3};
          right: 0;
          left: auto;
          &:before,
          &:after {
            width: 2rem;
            top: 1.6875rem;
            left: 0.9375rem;
          }
        `
      case 'gallery':
        return css`
          width: 3.75rem;
          height: 3.75rem;
          background: none;
          top: 2.5rem;
          right: 3.75rem;
          left: auto;
          &:before,
          &:after {
            width: 2rem;
            top: 1.6875rem;
            left: 0.9375rem;
          }

          @media (max-width: 1199px) {
            top: 1.875rem;
            right: 1.875rem;
          }
        `
    }
  }}

  @media (max-width: 1199px) {
    width: 3.75rem;
    height: 3.75rem;
    &:before,
    &:after {
      width: 2rem;
      top: 1.6875rem;
      left: 0.9375rem;
    }
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'L' | 'R' | 'compact' | 'gallery'
