import styled from '@emotion/styled'
import React, { memo } from 'react'

import {
  PrimaryMenuItem,
  Props as PrimaryMenuItemProps,
} from './PrimaryMenuItem'

export interface Props {
  menuItems: PrimaryMenuItemProps[]
  isSticky?: boolean
}

export const PrimaryMenu = memo(function PrimaryMenu({
  menuItems,
  isSticky,
}: Props) {
  if (!menuItems) {
    return null
  }

  return (
    <Container className={isSticky ? 'sticky' : undefined}>
      <List>
        {menuItems.map((item, index) => (
          <Li key={index}>
            <PrimaryMenuItem {...item} />
          </Li>
        ))}
      </List>
    </Container>
  )
})

const Container = styled.nav`
  margin: 0 auto 0 1.875rem;
  &.sticky {
    a {
      color: ${({ theme }) => theme.colors.variants.secondaryDark1};
    }
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const List = styled.ul`
  display: flex;
`

const Li = styled.li`
  margin-right: 1.5rem;
  &:last-of-type {
    margin-right: 0;
  }
`
