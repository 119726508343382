import * as React from 'react'
import { memo } from 'react'

const SvgPhone = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
  >
    <path d="m28.941 21.542-3.716-3.711a3.148 3.148 0 0 0-4.645 0l-.164.164c-.656.6-1.312 1.255-1.913 1.91l-.328-.164c-.273-.164-.6-.273-.82-.437a23.508 23.508 0 0 1-5.52-5.02 11.112 11.112 0 0 1-1.8-2.838l1.64-1.637.437-.437a3.116 3.116 0 0 0 0-4.693l-1.257-1.255-.547-.546-.6-.6-1.257-1.255a3.148 3.148 0 0 0-4.645 0l-.929.928c-.437.437-.874.928-1.366 1.364A4.793 4.793 0 0 0 .035 6.48a11.487 11.487 0 0 0 .874 5.02 29.492 29.492 0 0 0 5.137 8.568 31.8 31.8 0 0 0 10.493 8.186A15.683 15.683 0 0 0 22.551 30h.437a4.794 4.794 0 0 0 3.88-1.692c.437-.491.874-.873 1.312-1.364l.71-.709a3.078 3.078 0 0 0 .051-4.693Zm-1.366 3.329-.71.709a12.156 12.156 0 0 0-1.421 1.473 3.249 3.249 0 0 1-2.787 1.037 15.257 15.257 0 0 1-5.3-1.528 30.69 30.69 0 0 1-9.892-7.694 27.938 27.938 0 0 1-4.809-7.967 9.82 9.82 0 0 1-.765-4.2 2.834 2.834 0 0 1 .874-1.91c.492-.437.929-.928 1.421-1.364l.929-.928a1.418 1.418 0 0 1 .984-.491 1.418 1.418 0 0 1 .984.491L8.34 3.754l.6.6.547.546 1.257 1.255a1.223 1.223 0 0 1 0 2.019l-.437.437c-.6.6-1.2 1.2-1.858 1.8a1.393 1.393 0 0 0-.328 1.637 16.014 16.014 0 0 0 2.186 3.547 24.359 24.359 0 0 0 6.012 5.457 8.851 8.851 0 0 0 .984.546 5.081 5.081 0 0 1 .547.273 1.509 1.509 0 0 0 1.8-.273l2.131-2.128.164-.164a1.241 1.241 0 0 1 1.913 0c1.2 1.2 2.459 2.456 3.716 3.656a1.172 1.172 0 0 1 0 1.913Z" />
  </svg>
)

const Memo = memo(SvgPhone)
export default Memo
