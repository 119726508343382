import * as React from 'react'
import { memo } from 'react'

const SvgMq = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <path d="M10.049 20.001H.976a.976.976 0 0 1-.69-.286.976.976 0 0 1-.286-.69V9.952a.976.976 0 0 1 .286-.691.976.976 0 0 1 .691-.286.976.976 0 0 1 .69.286.976.976 0 0 1 .286.691v6.716l5.248-5.248a3.049 3.049 0 0 0 .635.918 3.078 3.078 0 0 0 .687.521l-5.189 5.19h6.716a.975.975 0 0 1 .691.286.975.975 0 0 1 .286.69.975.975 0 0 1-.286.691.977.977 0 0 1-.69.286Zm.017-8.686a1.22 1.22 0 0 1-.985-.521 1.221 1.221 0 0 1-.151-1.1 1.193 1.193 0 0 1 .287-.459 1.207 1.207 0 0 1 .462-.29 1.222 1.222 0 0 1 1.1.155 1.222 1.222 0 0 1 .52.984 1.225 1.225 0 0 1-.359.877 1.224 1.224 0 0 1-.867.359Zm8.47-.42a.976.976 0 0 1-.488-.845V3.334l-5.2 5.2a3.078 3.078 0 0 0-.521-.687 3.043 3.043 0 0 0-.918-.635l5.261-5.261H9.954a.977.977 0 0 1-.845-.488.976.976 0 0 1 0-.976.976.976 0 0 1 .845-.488h9.073a.976.976 0 0 1 .69.286.977.977 0 0 1 .286.69v9.073a.976.976 0 0 1-.488.845.973.973 0 0 1-.488.131.973.973 0 0 1-.492-.13Z" />
  </svg>
)

const Memo = memo(SvgMq)
export default Memo
