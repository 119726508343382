import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { HeaderContext } from 'app/containers/Header'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo, useContext, useEffect, useState } from 'react'

export interface Props {
  contactsPageURL?: string
}

export const Toolbars = memo(function Toolbars({ contactsPageURL }: Props) {
  const { IBEID, languageCode, languagePrefix } = useContext(
    HeaderContext,
  ) as any

  const [scrollDir, setScrollDir] = useState('up')

  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      setScrollDir(scrollY > lastScrollY ? 'down' : 'up')
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDir])

  return (
    <Container
      className={scrollDir == 'down' ? 'hidden' : ''}
      dial={5}
      row
      stretch
    >
      {contactsPageURL ? (
        <Item to={contactsPageURL}>
          {useVocabularyData('request', languageCode)}
        </Item>
      ) : null}

      <Anchor
        href={`https://www.simplebooking.it/ibe/hotelbooking/search?hid=${IBEID}&lang=${
          languagePrefix ? languagePrefix.toLocaleUpperCase() : 'IT'
        }`}
        rel="noopener"
        target="_blank"
      >
        {useVocabularyData('book', languageCode)}
      </Anchor>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  transition: 0.3s ease-out;
  &.hidden {
    transform: translateY(6rem);
  }
`

const Style = css`
  background: ${theme.colors.variants.neutralLight3};
  color: ${theme.colors.variants.neutralDark3};
  flex: 1;
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  letter-spacing: 0.05em;
  padding: 1.625rem 1.25rem;
  text-align: center;
  text-transform: uppercase;
  &:nth-of-type(2) {
    background: ${theme.colors.variants.primaryLight3};
    color: ${theme.colors.variants.neutralDark3};
  }
`

const Item = styled(Link)`
  ${Style}
`

const Anchor = styled.a`
  ${Style}
`
