import styled from '@emotion/styled'
import { IBEAnchor } from 'app/components/Common/Button/IBEAnchor'
import { HeaderContext } from 'app/containers/Header'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useContext } from 'react'

import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  onClick?: () => void
  menuItems: MenuItemProps[]
  modalStatus?: boolean
}

export const Navigation = memo(function Navigation({
  onClick,
  menuItems,
  modalStatus,
}: Props) {
  const { IBEID, languageCode, languagePrefix } = useContext(
    HeaderContext,
  ) as any

  const variants = {
    visible: (i: any) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.1,
        stiffness: 1000,
        velocity: -100,
      },
    }),
    hidden: { opacity: 0, x: -60 },
  }

  return (
    <Container onClick={onClick}>
      {menuItems ? (
        <Nav onClick={(e) => e.stopPropagation()}>
          <NavList>
            {menuItems.map((item, index) => (
              <motion.li
                key={index}
                custom={index}
                animate={modalStatus ? 'visible' : 'hidden'}
                variants={variants}
              >
                <MenuItem {...item} />
              </motion.li>
            ))}
            {IBEID ? (
              <motion.li
                className="ibecta"
                custom={menuItems.length + 1}
                animate={modalStatus ? 'visible' : 'hidden'}
                variants={variants}
              >
                <IBECTA
                  IBEID={IBEID}
                  languageCode={languageCode}
                  languagePrefix={languagePrefix}
                />
              </motion.li>
            ) : null}
          </NavList>
        </Nav>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    background: ${({ theme }) => theme.colors.variants.secondaryDark2};
    overflow-y: auto;
    -webkit-overflow-scrolling: auto;
    &:before {
      display: none;
    }
  }
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
  width: 64%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.secondaryDark2};
  padding-left: 9.028vw;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 1439px) {
    width: 80%;
  }

  @media (max-width: 1199px) {
    width: 100%;
  }

  @media (max-width: 1023px) {
    height: auto;
    align-items: flex-start;
    background: none;
    margin-bottom: 5.625rem;
    padding-top: 8.125rem;
    position: relative;
    top: auto;
    left: auto;
  }
`

const NavList = styled.ul`
  width: 100%;
  position: relative;
  li {
    margin-top: 3.5vh;
    &:first-of-type {
      margin-top: 0;
    }
    &.ibecta {
      display: none;
    }
  }

  @media (max-width: 1023px) {
    li {
      margin-top: 1.9375rem;
      &.ibecta {
        display: block;
      }
    }
  }
`

const IBECTA = styled(IBEAnchor)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.6875rem;
  text-transform: uppercase;
  transition: 0.2s ease-out;
  &:hover {
    opacity: 0.5;
  }
`
