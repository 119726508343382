import * as React from 'react'
import { memo } from 'react'

const SvgPlus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
  >
    <path d="M8 1v14M1 8h14" />
  </svg>
)

const Memo = memo(SvgPlus)
export default Memo
