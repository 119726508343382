import * as React from 'react'
import { memo } from 'react'

const SvgPinMap = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 27.045 32.5"
  >
    <g transform="translate(-1.75 .25)">
      <path d="M27.545 13.273C27.545 22.818 15.273 31 15.273 31S3 22.818 3 13.273a12.273 12.273 0 1 1 24.545 0Z" />
      <circle cx={3} cy={3} r={3} transform="translate(12.273 10)" />
    </g>
  </svg>
)

const Memo = memo(SvgPinMap)
export default Memo
