import * as React from 'react'
import { memo } from 'react'

const SvgEmail = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 30 22.508"
  >
    <path d="M30 1.573v19.362a2 2 0 0 1-.147.375 4.113 4.113 0 0 1-.519.726 1.89 1.89 0 0 1-1.413.465H2.034a1.866 1.866 0 0 1-1.644-.747 5.211 5.211 0 0 1-.39-.819V1.573a1.989 1.989 0 0 1 .147-.375A4.113 4.113 0 0 1 .669.472 1.89 1.89 0 0 1 2.082.007h25.932a1.833 1.833 0 0 1 1.6.747 5.1 5.1 0 0 1 .39.819Zm-1.869.3H1.869v2.941a.327.327 0 0 0 .141.216q2.01 1.38 4.029 2.748l4.413 3c1.47 1 2.937 2 4.41 3a.3.3 0 0 0 .267 0c.387-.252.765-.519 1.146-.78q2.025-1.393 4.025-2.784 2.4-1.662 4.827-3.315 1.458-1 2.916-2.007a.237.237 0 0 0 .09-.168c.006-.942 0-1.872 0-2.844Zm-1.368 18.753a1.2 1.2 0 0 0-.084-.12l-1.518-1.524q-2.85-2.847-5.7-5.7a.177.177 0 0 0-.276-.03c-1.308.9-2.625 1.8-3.933 2.718a.378.378 0 0 1-.5 0c-1.32-.9-2.649-1.8-3.969-2.7a.153.153 0 0 0-.24 0q-1.561 1.584-3.13 3.144-1.659 1.656-3.313 3.3c-.3.285-.6.552-.882.828l.051.072h23.5Zm1.356-13.518-7.167 4.932 7.167 7.17ZM9.036 12.079l-7.149-4.86v12Z" />
  </svg>
)

const Memo = memo(SvgEmail)
export default Memo
