import * as React from 'react'
import { memo } from 'react'

const SvgGift = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 647.516 685.952"
  >
    <path d="M586.591 146.664H453.318a84.668 84.668 0 1 0-134.15-98.306 76.416 76.416 0 1 0-116.415 98.306H60.922A60.987 60.987 0 0 0 0 207.586v44.085a61.005 61.005 0 0 0 54.786 60.607V608.64a77.408 77.408 0 0 0 77.318 77.312h383.3a77.406 77.406 0 0 0 77.322-77.322V312.269a61 61 0 0 0 54.786-60.607v-44.085a60.985 60.985 0 0 0-60.922-60.911ZM333.7 84.764a61.9 61.9 0 1 1 61.9 61.9h-61.9Zm-130.125 8.279a53.627 53.627 0 0 1 107.254 0v53.627h-53.627a53.7 53.7 0 0 1-53.627-53.627ZM22.859 251.67v-44.085a38.1 38.1 0 0 1 38.055-38.055h196.287l55.121.005v120.184H60.915a38.1 38.1 0 0 1-38.061-38.05Zm54.792 356.968V312.589h234.68v350.5H132.106a54.513 54.513 0 0 1-54.452-54.457Zm492.212 0a54.519 54.519 0 0 1-54.457 54.457H335.194V312.591h234.681Zm54.792-356.968a38.1 38.1 0 0 1-38.055 38.055H335.192v-120.19h251.407a38.1 38.1 0 0 1 38.055 38.055Z" />
  </svg>
)

const Memo = memo(SvgGift)
export default Memo
