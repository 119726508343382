import styled from '@emotion/styled'
import { CheckCircle, Gift } from 'app/components/Icons'
import { HeaderContext } from 'app/containers/Header'
import { motion } from 'framer-motion'
import { compact } from 'lodash'
import React, { memo, useContext } from 'react'

export interface Props {
  modalStatus?: boolean
}

export const Benefits = memo(function Benefits({ modalStatus }: Props) {
  const { benefits, languageCode } = useContext(HeaderContext) as any

  const variants = {
    visible: (i: any) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.15 + 0.3,
        duration: 0.3,
      },
    }),
    hidden: { opacity: 0, y: '-2.5rem' },
  }

  const translation = compact(
    benefits?.translations.filter(
      (t: any) => t.languages_code.code === languageCode,
    ),
  )[0] as any

  const checklist = translation.benefits
  const title = translation?.title || undefined

  if (checklist < 1) {
    return null
  }

  return (
    <Container
      className={modalStatus ? 'open' : undefined}
      onClick={(e) => e.stopPropagation()}
    >
      <Gift />

      {title ? (
        <Title
          initial={{ opacity: 0 }}
          animate={modalStatus ? { opacity: 1 } : undefined}
          transition={{
            delay: 0.2,
            duration: 0.4,
          }}
        >
          {title}
        </Title>
      ) : undefined}
      <Checklist>
        {checklist.map((item: any, index: any) => (
          <Check
            key={index}
            custom={index}
            animate={modalStatus ? 'visible' : 'hidden'}
            variants={variants}
          >
            <CheckCircle />
            <Label>{item.label}</Label>
          </Check>
        ))}
      </Checklist>
    </Container>
  )
})

const Container = styled.div`
  width: 26.5rem;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.primaryLight3};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding: 19.3vh 4.375rem 0;
  position: relative;
  transition: 0.3s ease-in-out;
  transform: translate(100%);
  &.open {
    transform: none;
  }

  > svg {
    width: auto;
    height: 76.1vh;
    fill: ${({ theme }) => theme.colors.variants.primaryLight1};
    opacity: 0.5;
    position: absolute;
    top: calc(100% - 38vh);
    left: 50%;
    transform: translateX(-50%);
  }
`

const Title = styled(motion.div)`
  max-width: 12.5rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.75rem;
`

const Checklist = styled.div`
  margin-top: 3.75rem;
  position: relative;
  z-index: 2;
`

const Check = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 1.5rem;
  &:first-of-type {
    margin-top: 0;
  }

  svg {
    width: 1.25rem;
    height: auto;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 2;
    margin-right: 1rem;
  }
`

const Label = styled.div`
  width: calc(100% - 2.25rem);
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  letter-spacing: 0.0094rem;
  line-height: 1.1875rem;
`
