import * as React from 'react'
import { memo } from 'react'

const SvgUser = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <path d="M13.713 10.706a6.037 6.037 0 1 0-7.457 0 10 10 0 0 0-6.25 8.179 1 1 0 0 0 .216.739 1.012 1.012 0 0 0 1.794-.518 7.981 7.981 0 0 1 2.631-5.068 8.073 8.073 0 0 1 10.716 0 7.981 7.981 0 0 1 2.631 5.068 1 1 0 0 0 .331.637 1.008 1.008 0 0 0 .674.253h.111a1.007 1.007 0 0 0 .67-.369 1 1 0 0 0 .214-.731 10 10 0 0 0-6.281-8.19Zm-3.728-.71a4.02 4.02 0 0 1-3.714-2.464 3.982 3.982 0 0 1 .872-4.356 4.032 4.032 0 0 1 6.185.606 3.981 3.981 0 0 1-.5 5.048A4.031 4.031 0 0 1 9.985 10Z" />
  </svg>
)

const Memo = memo(SvgUser)
export default Memo
