import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  id?: string
  title: string
  languageCode?: string
  languagePrefix?: string | null
  url?: string
}

export const PrimaryMenuItem = memo(function PrimaryMenuItem({
  title,
  url,
}: Props) {
  if (!title || !url) {
    return null
  }

  return (
    <Anchor to={url} activeClassName="active">
      {title}
    </Anchor>
  )
})

const Anchor = styled(Link)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 0.9375rem;
  &:hover {
    text-decoration: underline;
  }
`
