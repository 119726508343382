import * as React from 'react'
import { memo } from 'react'

const SvgMinus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 2"
  >
    <path d="M1 1h14" />
  </svg>
)

const Memo = memo(SvgMinus)
export default Memo
