import * as React from 'react'
import { memo } from 'react'

const SvgCheckCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 22.928 22.54"
  >
    <path d="M21.514 10.33v.944A10.256 10.256 0 1 1 15.432 1.9" />
    <path d="M21.514 3.069 11.258 13.335l-3.077-3.077" />
  </svg>
)

const Memo = memo(SvgCheckCircle)
export default Memo
