import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  code: string
  currentPrefix?: string
  pageID?: string
  languagePrefix: string | null
  URL: string
}

export const LanguageItem = memo(function LanguageItem({
  code,
  currentPrefix,
  languagePrefix,
  URL,
}: Props) {
  return (
    <Anchor
      className={currentPrefix === languagePrefix ? 'active' : undefined}
      to={URL}
    >
      {code}
    </Anchor>
  )
})

const Anchor = styled(Link)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0094rem;
  line-height: 1.1875rem;
  opacity: 0.4;
  margin-left: 2.125rem;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  &:first-of-type {
    margin-left: 0;
  }
  &:hover {
    text-decoration: underline;
  }
  &.active {
    cursor: default;
    opacity: 1;
    pointer-events: none;
  }
`
