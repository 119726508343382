import * as React from 'react'
import { memo } from 'react'

const SvgWhatsapp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
  >
    <path d="M10.181 0A9.781 9.781 0 0 0 .363 9.742a9.642 9.642 0 0 0 1.409 5.031L0 20l5.436-1.727A9.835 9.835 0 0 0 20 9.742 9.781 9.781 0 0 0 10.181 0Zm4.882 13.442a2.536 2.536 0 0 1-1.737 1.119c-.46.024-.474.357-2.984-.734a10.244 10.244 0 0 1-4.139-3.914 4.815 4.815 0 0 1-.926-2.61 2.782 2.782 0 0 1 .956-2.041.962.962 0 0 1 .68-.286c.2 0 .326-.006.472 0s.365-.026.556.474.645 1.748.7 1.875a.455.455 0 0 1 0 .436 1.705 1.705 0 0 1-.266.405c-.131.141-.276.314-.392.422s-.267.249-.129.5a7.513 7.513 0 0 0 1.329 1.774 6.85 6.85 0 0 0 1.969 1.309c.246.134.393.119.546-.041s.654-.7.83-.945.342-.2.569-.1 1.436.739 1.682.873.41.2.469.308a2.062 2.062 0 0 1-.184 1.176Z" />
  </svg>
)

const Memo = memo(SvgWhatsapp)
export default Memo
