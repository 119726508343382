import * as React from 'react'
import { memo } from 'react'

const SvgPhoneFull = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path d="m23.315 16.96-5.25-2.25a1.125 1.125 0 0 0-1.313.323l-2.325 2.841a17.375 17.375 0 0 1-8.305-8.306l2.841-2.325a1.122 1.122 0 0 0 .323-1.313L7.036.68A1.133 1.133 0 0 0 5.747.029L.872 1.154A1.125 1.125 0 0 0 0 2.25 21.748 21.748 0 0 0 21.75 24a1.125 1.125 0 0 0 1.1-.872l1.125-4.875a1.139 1.139 0 0 0-.66-1.293Z" />
  </svg>
)

const Memo = memo(SvgPhoneFull)
export default Memo
