import * as React from 'react'
import { memo } from 'react'

const SvgBooking = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
  >
    <path d="M28.875 29.999H1.081a1.061 1.061 0 0 1-1.08-1.039V3.851a1.019 1.019 0 0 1 .326-.744 1.109 1.109 0 0 1 .781-.3h5.307V1.034A1.066 1.066 0 0 1 7.5-.006a1.062 1.062 0 0 1 1.081 1.04v1.773h12.838V1.034A1.062 1.062 0 0 1 22.5-.006a1.066 1.066 0 0 1 1.085 1.04v1.773h5.334a1.062 1.062 0 0 1 1.082 1.044v25.106a1.027 1.027 0 0 1-.332.751 1.105 1.105 0 0 1-.748.289ZM2.206 27.915h25.61l.015-16.043H2.206ZM27.833 9.789V4.896h-4.247v1.736a1.067 1.067 0 0 1-1.083.977 1.067 1.067 0 0 1-1.083-.977V4.896H8.581v1.736a1.067 1.067 0 0 1-1.083.977 1.067 1.067 0 0 1-1.083-.977V4.896H2.206v4.893ZM19.8 23.266h-9.6a1.1 1.1 0 0 1-1-.5 1.012 1.012 0 0 1 0-1.082 1.1 1.1 0 0 1 1-.5h9.6a1.1 1.1 0 0 1 1 .5 1.012 1.012 0 0 1 0 1.082 1.1 1.1 0 0 1-.928.5ZM6.587 18.611a1.061 1.061 0 0 1-1.016-1.04 1.062 1.062 0 0 1 1.016-1.043h16.827a1.062 1.062 0 0 1 1.016 1.043 1.061 1.061 0 0 1-1.016 1.04Z" />
  </svg>
)

const Memo = memo(SvgBooking)
export default Memo
