import styled from '@emotion/styled'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  className?: string
  IBEID: number
  label?: string
  languageCode: string
  languagePrefix?: string | null
}

export const IBEAnchor = memo(function IBEAnchor({
  className,
  IBEID,
  label = 'book-now',
  languageCode,
  languagePrefix,
}: Props) {
  if (!IBEID) {
    return null
  }

  return (
    <Container
      className={className}
      href={`https://www.simplebooking.it/ibe/hotelbooking/search?hid=${IBEID}&lang=${
        languagePrefix ? languagePrefix.toLocaleUpperCase() : 'IT'
      }`}
      rel="noopener"
      target="_blank"
    >
      <Label>{useVocabularyData(label, languageCode)}</Label>
    </Container>
  )
})

const Container = styled.a``

const Label = styled.span``
