import * as React from 'react'
import { memo } from 'react'
const SvgShop = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 12.322 16"
  >
    <path d="M1.379 16a1.34 1.34 0 0 1-.988-.391A1.34 1.34 0 0 1 0 14.62V5.057a1.34 1.34 0 0 1 .391-.989 1.34 1.34 0 0 1 .988-.391h1.7v-.6a2.972 2.972 0 0 1 .9-2.184 2.972 2.972 0 0 1 2.184-.9 2.972 2.972 0 0 1 2.184.9 2.972 2.972 0 0 1 .9 2.184v.6h1.7a1.34 1.34 0 0 1 .989.391 1.34 1.34 0 0 1 .391.989v9.563a1.34 1.34 0 0 1-.391.989 1.34 1.34 0 0 1-.989.391ZM3.724 3.678H8.6v-.6a2.357 2.357 0 0 0-.7-1.736 2.357 2.357 0 0 0-1.736-.7 2.357 2.357 0 0 0-1.736.7 2.357 2.357 0 0 0-.7 1.736Zm5.2 3.4a.312.312 0 0 0 .23-.092.312.312 0 0 0 .092-.23V4.319H8.6v2.437a.312.312 0 0 0 .092.23.312.312 0 0 0 .228.094Zm-5.517 0a.312.312 0 0 0 .23-.092.312.312 0 0 0 .092-.23V4.319h-.644v2.437a.312.312 0 0 0 .092.23.312.312 0 0 0 .223.094Z" />
  </svg>
)
const Memo = memo(SvgShop)
export default Memo
