import * as React from 'react'
import { memo } from 'react'

const SvgWhatsappStroke = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
  >
    <path
      data-name="Icon awesome-whatsapp"
      d="M25.507 4.359a14.871 14.871 0 0 0-23.4 17.94L0 30l7.882-2.069a14.821 14.821 0 0 0 7.1 1.808h.007A15.009 15.009 0 0 0 30 14.873a14.925 14.925 0 0 0-4.493-10.514ZM14.993 27.234A12.334 12.334 0 0 1 8.7 25.513l-.45-.263-4.674 1.221 1.246-4.56-.295-.469a12.38 12.38 0 1 1 22.962-6.569 12.494 12.494 0 0 1-12.5 12.362Zm6.777-9.254c-.368-.187-2.2-1.085-2.538-1.205s-.589-.187-.837.187-.958 1.205-1.179 1.46-.435.281-.8.094a10.111 10.111 0 0 1-5.056-4.42c-.382-.656.382-.609 1.092-2.029a.688.688 0 0 0-.033-.65c-.094-.187-.837-2.016-1.145-2.759-.3-.723-.609-.623-.837-.636s-.462-.013-.71-.013a1.376 1.376 0 0 0-.991.462 4.174 4.174 0 0 0-1.3 3.1 7.277 7.277 0 0 0 1.513 3.844 16.6 16.6 0 0 0 6.348 5.612c2.357 1.018 3.281 1.1 4.46.931a3.8 3.8 0 0 0 2.5-1.768 3.107 3.107 0 0 0 .214-1.768c-.085-.172-.333-.261-.701-.442Z"
    />
  </svg>
)

const Memo = memo(SvgWhatsappStroke)
export default Memo
