import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Bottom } from './Bottom'
import { Contacts } from './Contacts'
import { Props as MenuItemProps } from './MenuItem'
import { Social } from './Social'

export interface Props {
  address?: string
  email?: string
  facebookURL?: string
  IBEID?: number
  instagramURL?: string
  youtubeURL?: string
  languageCode: string
  languagePrefix?: string | null
  logo?: string
  logoTripadvisor?: string
  phone?: string
  policyMenu?: { [key: string]: MenuItemProps[] }[]
  siteName?: string
  vat?: string
  whatsapp?: string
}

export const Footer = memo(function Footer({
  address,
  email,
  facebookURL,
  IBEID,
  instagramURL,
  youtubeURL,
  languageCode,
  languagePrefix,
  logo,
  logoTripadvisor,
  phone,
  policyMenu,
  siteName,
  vat,
  whatsapp,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {logo ? (
          <Brand>
            <Logo src={logo} alt={siteName} width="69" height="80" />
          </Brand>
        ) : null}
        {siteName ? <SiteName>{siteName}</SiteName> : null}
        {address ? <Address>{address}</Address> : null}

        <Contacts
          email={email}
          IBEID={IBEID}
          languageCode={languageCode}
          languagePrefix={languagePrefix}
          phone={phone}
        />

        <Social
          instagramURL={instagramURL}
          facebookURL={facebookURL}
          youtubeURL={youtubeURL}
          whatsapp={whatsapp}
        />

        <Bottom
          languageCode={languageCode}
          policyMenu={policyMenu}
          siteName={siteName}
          vat={vat}
        />
      </Wrapper>

      {logoTripadvisor ? (
        <Tripadvisor
          alt="TripAdvisor"
          src={logoTripadvisor}
          width="75"
          height="90"
        />
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.secondaryDark2};
  padding: 4.6875rem 6.667vw;
  text-align: center;

  @media (max-width: 767px) {
    padding: 3.75rem 1.875rem;
  }
`

const Wrapper = styled.div`
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: calc(100% - 3.75rem);
    border: 0.0625rem solid
      ${({ theme }) => theme.colors.variants.secondaryDark1};
    position: absolute;
    top: 2.5rem;
    left: 0;
  }

  @media (max-width: 767px) {
    &:before {
      display: none;
    }
  }
`

const Brand = styled.div`
  display: inline-block;
  background: ${({ theme }) => theme.colors.variants.secondaryDark2};
  margin: 0 auto 3.9375rem;
  padding: 0 4.861vw;
  position: relative;

  @media (max-width: 767px) {
    padding: 0;
  }
`

const Logo = styled.img``

const SiteName = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1rem;
  line-height: 1.1875rem;
  margin-bottom: 0.6875rem;
  text-transform: uppercase;
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.1875rem;
`

const Tripadvisor = styled.img`
  margin-top: 2.5rem;
`
