import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { LanguageItem, Props as LanguageItemProps } from './LanguageItem'

export interface Props {
  languagesList: LanguageItemProps[]
  languagePrefix: string
}

export const Languages = memo(function Languages({
  languagesList,
  languagePrefix,
}: Props) {
  return (
    <Container dial={3} row>
      {languagesList
        .sort((a: any, b: any) => a.sort - b.sort)
        .map((item, index) => (
          <LanguageItem currentPrefix={languagePrefix} key={index} {...item} />
        ))}
    </Container>
  )
})

const Container = styled(FlexBox)`
  position: absolute;
  top: 3.4375rem;
  right: calc(36% + 8.333vw);

  @media (max-width: 1439px) {
    right: calc(20% + 8.333vw);
  }

  @media (max-width: 1199px) {
    top: 1.25rem;
    right: 1.875rem;
  }
`
